
<!-- 乡镇预报 -->
<template>
  <div  class="page_box">
   
  </div>
</template>

<script>
export default {
  name: "yzyb",
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {


  },

  filters: {

  }
}
</script>

<style lang="scss" scoped>
</style>
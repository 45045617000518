
<!-- 雾与霾预报 -->
<template>
  <!-- <div  class="page_box"> -->
  <div >
    <div class="page_bg"></div>
    <p>{{fog.type}}</p>
    <img class="img_size" @click="lookImg(fog.ImgUrl)" :src="fog.ImgUrl" alt="">
    <p>{{Haze.type}}</p>
    <img class="img_size" @click="lookImg(fog.ImgUrl)" :src="Haze.ImgUrl" alt="">
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
import { GetWxytListS } from "./api"
export default {
  name: "wmyb",
  data() {
    return {
      fog:{},
      Haze:{}
    }
  },
  created() {
    this.GetWxytListSFun()
  },
  mounted() {
    
  },
  methods: {
    lookImg(url){
      ImagePreview([url])
    },
    async GetWxytListSFun(){
      var { data, code} = await GetWxytListS();
      if(code == 'ok'){
        this.fog = data[0]
        this.Haze = data[1];
      }
    }

  },

  filters: {

  }
}
</script>

<style lang="scss" scoped>
.page_box{
  color: #333;
}
.page_bg{
  position: absolute;
  width: 100%;
  height: 98%;
  z-index: -1;
  background-color:rgba($color: #fff, $alpha: .5);
  backdrop-filter:blur(5px);
}
.img_size{
  width:100%;
}
</style>
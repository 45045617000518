
function range(beginTime, endTime) {
  this.beginTime = beginTime;
  this.endTime = endTime;
}

function timeFormat(time, fmt = 'yyyy/MM/dd HH:mm:ss') {
  time = new Date(time);
  var o = {
    'M+': time.getMonth() + 1, //月份
    'd+': time.getDate(), //日
    'h+': time.getHours() % 12 == 0 ? 12 : time.getHours() % 12, //小时
    'H+': time.getHours(), //小时
    'm+': time.getMinutes(), //分
    's+': time.getSeconds(), //秒
    'q+': Math.floor((time.getMonth() + 3) / 3), //季度
    S: time.getMilliseconds(), //毫秒
  };
  var week = {
    '0': '/u65e5',
    '1': '/u4e00',
    '2': '/u4e8c',
    '3': '/u4e09',
    '4': '/u56db',
    '5': '/u4e94',
    '6': '/u516d',
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (time.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? '/u661f/u671f' : '/u5468') : '') + week[time.getDay() + '']);
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }
  return fmt;
}

/**
 * 
 * @param {*} startTimes 开始时间
 * @param {*} endTimes  结束时间
 * @param {*} amount  时间间隔 秒单位
 */

// function timeRange(startTimes, endTimes, amount){

// }
/*
* 调用实例
  timeRange('2020-06-04 00:00:00', '2020-06-04 24:00:00', 24)
*
*/
function timeRange(startTimes, endTimes, amount) {
  startTimes = Date.parse(new Date(startTimes)); // 计算开始时间的时间戳
  endTimes = Date.parse(new Date(endTimes)); // 计算结束时间的时间戳
  var timeAll = endTimes - startTimes; // 总时间间隔
  if (endTimes <= startTimes) {
    return -1;
  }
  if (amount < 2) {
    return -2;
  }
  try {
    var timeRange = new Array(); // return数组初始化
    var timeGap = timeAll / amount; // 分割后时间间隔
    var slidingTime = 0;
    var momentTime_front = '';
    var momentTime_rear = '';
    for (var i = 0; i < amount; i++) {
      momentTime_front = timeFormat(startTimes + timeGap * i);
      momentTime_rear = timeFormat(startTimes + timeGap * (i + 1) - 1000);
      // timeRange.push(new range(momentTime_front, momentTime_rear));
      timeRange.push({beginTime:momentTime_front, endTime:momentTime_rear, index:i});
    }

    return timeRange;
  } catch (e) {
    console.log('出错了：' + e);
  }
}
export { timeFormat, timeRange };


<!-- 雷电监测 -->
<template>
  <div class="page_box">
    <div class="map_box" id="ldjcMap"></div>
    <div class="release">
      <div class="ld_info">
        <van-icon class="location_cl" name="location" />
        <div>
          <div class="item1">
            <label for="">齐齐哈尔|建华区</label>
            <span>2020-07-28 14:41发布</span>
          </div>
          <div class="item2">
            <span>新疆路86号</span><br/>
            <span>温度56° | 湿度86% | 空气质量优24 </span>
          </div>
          <div class="item3">
            <label for="">未来两个小时无雷电</label>
          </div>
        </div>
      </div>
      <radar-timeline ref="radarTimeline"></radar-timeline>
    </div>
    <div class="op_layer element_list">
      <ul>
        <li class="element_item" :class="{ 'switch': it.open }" v-for="it of elementList" :key="it.name"
          @click="switchEl(it, 'elementList')">
          <ul class="wendu_box" v-if="it.tid == 'menu'" v-show="isListState">
            <li class="element_item" :class="{ 'switch': item.open }" v-for="item of listState" :key="item.tid"
              @click.stop="switchEl(item, 'listState')">
              <img v-show="!item.open" :src="require('../../../assets/elementmenu/' + item.img + '1' + '.png')" alt="" />
              <img v-show="item.open" :src="require('../../../assets/elementmenu/' + item.img + '.png')" alt="" />
              <div>{{ item.name }}</div>
            </li>
          </ul>
          <img v-show="!it.open" :src="require('../../../assets/elementmenu/' + it.img + '1' + '.png')" alt="" />
          <img v-show="it.open" :src="require('../../../assets/elementmenu/' + it.img + '.png')" alt="" />
          <div>{{ it.name }}</div>
        </li>
        <li class="element_item" @click="configFun()">
          <img src="../../../assets/elementmenu/config1.png" alt="" />
          <div>设置</div>
        </li>
      </ul>
    </div>
    <div class="op_layer wei_zhi">
      <ul>
        <li class="element_item">
          <img src="../../../assets/elementmenu/position.png" alt="" />
          <div>位置</div>
        </li>
        <li class="element_item">
          <img src="../../../assets/elementmenu/legend.png" alt="" />
          <div>图例</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import radarTimeline from "@/components/radarTimeline"
export default {
  components:{
    radarTimeline
  },
  name: "ldjc",
  data() {
    return {
      tdMap:null,
      isListState: false,
      elementList: [
        { name: '雷达', img: "leida", key: 'TMP', tid: 'leida', open: true },
        { name: '降水', img: "jiangyu", key: 'ER03', tid: 'jiangyu', open: false },
        { name: '更多', img: "menu", key: 'EDA10', tid: 'menu', open: false },
      ],
      listState: [
        { name: '雷达', img: "leida", key: 'TMP', tid: 'shezhi', open: false },
        { name: '雷达', img: "leida", key: 'TMP', tid: 'shezhi1', open: false },
        { name: '雷达', img: "leida", key: 'TMP', tid: 'shezhi2', open: false },
      ],
    }
  },
  created() {

  },
  mounted() {
    this.mapCreat()
  },
  methods: {
    // 选择要素
    switchEl(el,key){
      this[key].forEach((it) => { it.open = false });
      el.open = true;
      if(el.tid == 'menu' && el.open) return this.isListState = true;
      if(this.isListState) setTimeout(()=>{this.isListState = false},200)
      console.log(el)
    },
    // 选择设置
    configFun(el){
      console.log(el)
    },
    // 地图创建
    mapCreat() {
      // 来源于index.html中引入的 ./public/js/map/gdMap.js
      tdMap.map = new AMap.Map('ldjcMap', {
        zoom: 8,
        center: [124.05861816, 47.63122559],
        resizeEnable: true,
        expandZoomRange: true,
        zoomEnable: true,
        scrollWheel: true
      });
      this.tdMap = tdMap;
      this.tdMap.addAreaLine(230000, 3);
      this.tdMap.map.on('click', (ev) => {
        var { lat, lng } = ev.lnglat;
        console.log( lat, lng)
      
      });
    },
  },

  filters: {

  }
}
</script>

<style lang="scss" scoped>
.release{
  width: 95%;
  padding: 5px;
  min-height: 120px;
  box-sizing: border-box;
  background-color: #fff;
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translateX(-50%);
  color: #333;
  font-size: 13px;
}
.op_layer {
  position: absolute;
}
.element_list,.wei_zhi{
    .switch {
      background-color: rgba(35, 97, 206, .9) !important;
      >div {
        color: #fff !important;
      }
    }

    .element_item {
      position: relative;
      border-radius: 5px;
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      padding: 3px 0;
      margin-left: 10px;

      >img {
        display: block;
        width: 20px;
        margin: auto;
      }

      >div {
        white-space: nowrap;
        font-size: 11px;
        text-align: center;
        color: #628FF3;
      }

      .wendu_box {
        position: absolute;
        top: 0%;
        right: 50px;
        display: flex;

      }
    }

    >ul>.element_item {
      margin: 10px 0;
    }
  }
.element_list{
  right: 2%;
  top: 20%;
  .element_item{
    background-color: rgba(255,255,255,.9);
    >div {color: #628FF3;}
    // background-color: rgba(234, 243, 252, .9);
  }
   
}
.wei_zhi{
  left: 2%;
  bottom: 20%;
  .element_item{
    background-color: rgba(0,0,0,.7);
    >div {color: #fff;}
    // background-color: rgba(234, 243, 252, .9);
  }
}
.map_box{
  width: 100%;
  height: 100%;
}
.ld_info{
    display: flex;
    align-items: baseline;
    >div{width:100%;}
  .location_cl{
    font-size: 18px;
    margin-right: 5px;
  }
  .item1, .item3{
    label{
      font-size: 16px;
      color: #333;
      font-weight: 500;
    }
  }
  .item1{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #ADAEB2;
  }
  .item2{
    color: #ADAEB2;
  }
}
</style>

<template>
  <div class="vue-city-picker" @click.stop="selCityCodeFun()">
      <ul class="picker_box">
        <li class="item_picker" v-for="(item,index) of china_code" :key="index">
          <div class="title_name">
            <span>{{item.level}}</span>
          </div>
          <ul class="city_list">
            <li class="city_item" :class="{ activeCode: city.code == cityCode}" @click.stop="selCityCodeFun(city)" v-for="(city,code) of item.child" :key="code">{{city.city}}</li>
          </ul>
        </li>
      </ul>
  </div>
</template>

<script>
import china_code from "./china_code.json"
export default {
    props: {
    },
    data () {
        return{
          china_code:china_code,
          cityCode:null
        }
    },
    created(){
      // setTimeout(() => {
      //   this.selCityCodeFun(this.china_code[0].child[0])
      // }, 500);
    },
    mounted(){
      this.selCityCodeFun(this.china_code[0].child[0])
    },
    methods:{
      selCityCodeFun(item){
        if(!item)return
        this.cityCode = item.code;
        console.log(item);
        this.$emit('selectCity',item);
      }
    }
}
</script>

<style lang="scss" scoped>
  .vue-city-picker{
    position: absolute;
    top: 40px;
    left: 50%;
    height: 500px;
    width: 95%;
    transform: translate(-50%, 0%);
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 1px 5px 5px rgba($color: #000000, $alpha: 0.5);
    z-index: 100;
  }
  .activeCode{
    background-color: #66CBFF !important;
    color: #fff;
  }
  .picker_box{
    padding: 5px;
    color: #333;
    .item_picker{
      text-align: left;
      .title_name{
        height: 30px;
        font-size: 15px;
        line-height: 30px;
        border-bottom: 1px solid #c9c9c9;
        span{
          padding-bottom: 6px;
          padding-left: 10px;
          padding-right: 10px;
          margin: 0;
          border-bottom: 2px solid #339DDB;
        }
      }
      .city_list{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding:5px 8px;
        .city_item{
          margin: 5px;
          background-color: #E9E9E9;
          padding: 2px 8px;
          border-radius: 3px;
          font-size: 15px;
        }
      }
    }
  }
</style>
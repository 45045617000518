

import request from "@/utils/request.js";

// http://qqhrqxj.com/api/Wechat/GetProvinceTqyb
export function GetProvinceTqyb(params) {
  return request({
    url: "/api/Wechat/GetProvinceTqyb",
    method: "get",
    params
  });
}

// 雾与霾 http://qqhrqxj.com/api/Wechat/GetWxytListS
export function GetWxytListS(params) {
  return request({
    url: "/api/Wechat/GetWxytListS",
    method: "get",
    params
  });
}
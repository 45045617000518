<template>
  <div>
    <div class="time_line">
      <div class="circle">
        <van-icon color="#4D9A8E" @click="play" :name="isPlay ? 'pause-circle' : 'play-circle'" />
      </div>
      <ul ref="ul" class="timeline_box">
        <li v-for="(item, index) of lineData" :class="{ 'activeLine': timeIndex.index === index }" :key="item.endTime"
          @touchmove="ev => moved(ev, item)" @click="onClick(item)" @touchend="onEnd" @touchstart="downFun">
          <span v-show="index%6==0" class="scale">{{formatTime(item)}}</span>
          <span v-show="index==timeIndex.index" class="active">{{formatTime(timeIndex)}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { timeFormat, timeRange } from "./common";
export default {
  props: {

  },

  data() {
    return {
      isPlay: false, // 播放
      interval: 5, // 时间间隔（分钟）
      length: 3, // 时间总长度（小时）
      lineData: [], // 进度集合
      timeIndex: {}, // 当前选中
      down:{
        flags: false,
        position: { x: 0, y: 0 },
        nx: "",
        ny: "",
        dx: "",
        dy: "",
        xPum: "",
        yPum: "",
      }
    }
  },
  created() {
    this.initTimeline();

  },
  methods: {
    initTimeline() {
      var date = new Date(new Date().setHours(new Date().getHours() - 1));
      var m = date.getMinutes() - (date.getMinutes()) % this.interval;
      var part = this.length * 60 / this.interval;
      var start = new Date(date.setMinutes(m));
      var end = new Date(date.setHours(start.getHours() + this.length));
      this.lineData = timeRange(start, end, part);
      
      var item =  this.lineData.filter(it=>{
        var dq = new Date().setMinutes(m);
        return timeFormat(new Date (it.endTime),'yyyy/MM/dd HH:mm:00') === timeFormat(dq,'yyyy/MM/dd HH:mm:00');
      })[0]
      this.timeIndex = this.lineData[item.index];
    },
    // 点击操作
    onClick(item) {
      this.markerClick(item);
    },

    // 当前选中
    markerClick(item = this.timeIndex) {
      console.log(item.endTime,item.index);
      this.timeIndex = item;
      this.$emit("handlePlay", this.timeIndex);
    },

    // 播放
    play() {
      this.isPlay = !this.isPlay;
      if (this.isPlay) {
        this.timer = setInterval(() => {
          this.nextMove();
        }, 500);
      } else {
        clearInterval(this.timer);
      }
    },
    // 播放中...
    nextMove() {
      var index = this.timeIndex.index;
      index++;
      if(index > this.lineData[this.lineData.length-1].index) index = 0
      this.timeIndex = this.lineData[index];
      this.markerClick();
    },

    formatTime(item){
      return timeFormat(new Date(item.endTime), 'HH:mm');
    },

    // 实现移动端拖拽
    downFun(event) {
      this.down.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.down.dx = touch.clientX;
      this.down.dy = touch.clientY;
    },

    // 滑动操作
    moved(event) {
      event.preventDefault();
      if (this.down.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.down.nx = touch.clientX - 60 - this.down.position.x;
        this.down.ny = touch.clientY - this.down.position.y;
        var ulW = this.$refs['ul'].offsetWidth;
        if (this.down.nx < 0) {
          this.down.nx = 0;
        } else if (this.down.nx > ulW) {
          this.down.nx = ulW;
        }
        var index = Math.round(this.down.nx/event.target.offsetWidth);
        if(this.lineData[index])this.markerClick(this.lineData[index])
      }
    },

    //鼠标释放
    onEnd() {
      this.down.flags = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.activeLine {
  border-right: 0.5px solid blue;
}

.time_line {
  display: flex;
  align-items: center;

  .circle {
    font-size: 24px;
  }
  .timeline_box li:first-child{
    background-color: #DAD5FA;
  }
  .timeline_box {
    margin: 15px 0;
    margin-left: 5px;
    background-color: #E3E3E3;
    list-style: none;
    width: 100%;
    height: 18px;
    border-radius: 3px;
    display: flex;
    .scale{
      font-size: 12px;
      font-weight: bold;
      position: absolute;
      top: 110%;
      left: -110%;
    }
    .active{
      font-size: 12px;
      font-weight: bold;
      position: absolute;
      top: -100%;
      left: -110%;
      background-color: #333;
      border-radius: 3px;
      color: #fff;
      padding: 2px 3px;
    }
    li {
      flex: 1;
      position: relative;
      box-sizing: border-box;
    }
  }
}
</style>

<!-- 全省预报 -->
<template>
  <div class="page_box" v-if="showTqyb">
      <div class="map_box" id="qsybMap"></div>
      <!-- <div class="release">
        <div class="release_label">
          <span>发布单位:黑龙江省气象台</span> 
          <span>全省天气预报2022年7月20日10时</span> 
        </div>
        <p :class="txtOpen?'showTotal':'text'">
          可展示全市站点72小时以内的数据，气温（实时气温、今日最高、今日最低）；降水（近1小时、近3小时、近6小时、近12小时、近24小时、近48小时、近72小时、08时以来降水、20时以来降水）；风力：2分平均风、极大风；其它（气压、能见度、相对湿度）以折线图和色斑图两种方式展示（注：需提供天擎或者CIMISS数据）（数据是72小时的、选项1小时、3小时去掉，色斑图默认08-08,20-20，有时间选项可以在线生成，可以选国家站和全部站，两种色斑图类型---站点选择模糊查询站号站名，折线图可以左右滑动，）本域内站点数据查询、统计、排名（72小时以内数据）可根据日期及小时进行检索，如2022年3月1日09时，气温：（实时气温、最高气温、最低气温）降水：（近1小时、近3小时、近6小时、近12小时、近24小时、近48小时、近72小时）风力：（实时极大风、风向；日极大风、风向）湿度：（实时湿度、日最低湿度、日最高湿度）
          气压：（实时气压、日最高气压、日最低气压）能见度：（能见度、日最低能见度）（注：需提供天擎或者CIMISS数据）（数据统计，站点选择全部站，国家站，以逗号分隔填写站号）
        </p>
        <van-icon class="open_btn" @click="txtOpen = !txtOpen" :name="txtOpen?'arrow-up':'arrow-down'" />
    </div> -->
    
  </div>
  <div v-else>
    <!-- <div class="ShowYb" @click="ShowYbFun">返回</div> -->
    <van-nav-bar
      class="ShowYb"
      title="全省预报"
      left-text="返回"
      right-text=""
      left-arrow
      @click-left="ShowYbFun"
    />
    <TianQiYuBao :position="position" ref="TianQiYuBao"></TianQiYuBao>
  </div>
 
</template>

<script>
import { GetProvinceTqyb } from "./api.js"
import TianQiYuBao from"../TianQiYuBao.vue"
export default {
  components:{
    TianQiYuBao
  },
  name: "qsyb",
  data() {
    return {
      showTqyb:true,
      tdMap:null,
      txtOpen:false,
      position:{},
    }
  },
  created() {
    this.getShengData()

  },
  mounted() {
    this.mapCreat();
  },
  methods: {
    ShowYbFun(){
       this.showTqyb=true;
       this.$emit('showTab', this.showTqyb)
       setTimeout(()=>{
        this.mapCreat();
        this.getShengData();
       },500)
       
    },
    // 获取全省预报
    async getShengData(){
      const res = await GetProvinceTqyb();
      const { data, code } = res;
      if(code != 'ok') return
      data.forEach(it => {
        var getDay = (day) => {
          var item = it.WeatherYb_24h.filter(it=>{
            var dey1 = it.Time.split('/');
            return day===Number(dey1[2]);
          })[0]
          return item
        }
        var jt = getDay(new Date().getDate()), mt = getDay(new Date().getDate()+1);
        var html = `<div style="font-size: 12px;border-radius: 5px;color:#fff;width:100px;height:50px;background-color: #2F7A71;display: flex;flex-direction: column;justify-content: center;align-items: center;">
        <p style="margin:0;">${it.City}</p>
        <p style="margin:0;display: flex;align-items: center;">
          <img style="width: 20px;margin-right: 5px;" src="${require('@/assets/最新天气图标/'+ jt.Weather_Day+'.png')}" alt="">
          ${jt.Temperature_Min} - ${jt.Temperature_Max}℃
        </p>
        </div>`
        this.addMapMarkers([it.Lon,it.Lat], html, {...it,mt});
      });
      
    },
    // 添加地图标注点
    addMapMarkers(position,content,obj){
      var marker = new AMap.Marker({
          content: content,  // 自定义点标记覆盖物内容
          position: position, // 基点位置
          riseOnHover:true,
          data:obj,
          offset: new AMap.Pixel(0, 0) // 相对于基点的偏移位置
      });
      this.tdMap.map.add(marker);
      marker.on('click', ev => {
        //当前标记居中
        var obj = marker.w.data;
        console.log(obj)
        var day = obj.mt.Time.split('/');
        var html = `<div style="font-size: 15px;color:#333;">
          <h1 style="font-size: 18px">${obj.City}</h1>
          <span>
          ${day[1]}/${day[2]}（明天）<br >${obj.mt.Weather_Day}${obj.mt.Temperature_Min} - ${obj.mt.Temperature_Max}℃${obj.mt.WindDirection_Day}
          </span>
          <div onclick="getLen(${obj.Lon},${obj.Lat})" id="qsybinfo" style="margin: 5px 0;text-align: center;color:#fff;background-color: #2F7A71;border-radius: 5px;">查看详情</div>
        </div>`
        var infoWindow = new AMap.InfoWindow({
          content: html,
          offset: new AMap.Pixel(50, 0)
        });
         window.getLen = (lng,lat)=>{
          console.log(lng,lat)
          this.showTqyb = false
          this.$emit('showTab', this.showTqyb)
          this.position = { lng, lat }
        }
        infoWindow.open(this.tdMap.map,[obj.Lon,obj.Lat]);
        // document.getElementById('qsybinfo').
      });
    },

    // 地图创建
    mapCreat() {
      // 来源于index.html中引入的 ./public/js/map/gdMap.js
      tdMap.map = new AMap.Map('qsybMap', {
        zoom: 5.8,
        center: [115.94970703, 39.24316406],
        resizeEnable: true,
        expandZoomRange: true,
        zoomEnable: true,
        scrollWheel: true
      });
      this.tdMap = tdMap;
      // this.tdMap.addAreaLine(230000, 3, '#3C439A');
      this.tdMap.map.on('click', (ev) => {
        var { lat, lng } = ev.lnglat;
        console.log(lat, lng)
      
      });
    },

  },
}
</script>
<style>
#qsybMap .amap-info-close{
  transform: translateX(15px);
}
</style>

<style lang="scss" scoped>
.map_box{
  width: 100%;
  height: 100%;


}
.ShowYb{
  background-color: transparent;
  ::v-deep .van-nav-bar__title, 
  ::v-deep .van-nav-bar__left .van-icon,
  ::v-deep .van-nav-bar__left .van-nav-bar__text{
    color: #fff;
  } 
  

}
.release{
  width: 95%;
  padding: 5px;
  min-height: 120px;
  box-sizing: border-box;
  background-color: #fff;
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translateX(-50%);
  color: #333;
  font-size: 13px;
  .release_label{
    span{
      display: block;
    }
    margin-bottom: 5px;
  }
  >p{
    margin: 0;
    word-break:break-all;
    display:-webkit-box;  // 对象作为伸缩盒子模型展示
    -webkit-box-orient:vertical;  // 设置或检索伸缩盒子对象的子元素的排列方式
    transition: -webkit-box 0.5s;
  }
  .text{
    -webkit-line-clamp:3;  // 显示的行数 
    overflow:hidden;  // 隐藏超出的内容
  }
  .showTotal{
    -webkit-line-clamp: none;
    overflow:visible;
  }
  .open_btn{
    width: 100%;
    text-align: center;
    font-size: 24px;
  }
}
</style>